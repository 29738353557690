import React, {useContext, useState, useEffect} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Transition } from 'react-transition-group'
import anime from 'animejs'
import Loading from '../../../../../apollo/Loading'

function CustomBlock() {

  // Animating fade in/out
  const baseDuration = 1000
  const fadeInLogo = element => {
    anime
      .timeline()
      .add({
        targets: element,
        opacity: [0, 1],
        duration: baseDuration,
        easing: 'easeInOutSine',
      })
  }
  const fadeOutLogo = element => {
    anime
      .timeline()
      .add({
        targets: element,
        opacity: [1, 0],
        duration: baseDuration / 2,
        easing: 'linear'
      })
  }
  
    const apollo = useContext(ApolloContext)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [windowSize, setWindowSize] = useState()
    const [activeSlide, setActiveSlide ] = useState(0)
    let numberOfSlides

    const posts = apollo.data?.events.nodes.map((node, i) => {
     
        return (
            <article onClick={() => setActiveSlide(i)} className={`single-post slide-${currentSlide}`} key={i}>
                <div className="img-container">
                    <div className="img-wrapper">
                        <img srcSet={node.featuredImage.node.srcSet} />
                    </div>
                </div>             
            </article>
        )
    })

    
    const activePost = apollo.data?.events.nodes.map((node, i) => {
     if( activeSlide === i )
      return (
          <div className={`single-slide grid-12 ${activeSlide === i ? '' : ''}`} key={i}>
           	<Transition
              in={activeSlide === i ? true :	false}
              timeout={baseDuration}
              appear={true}
              onEntering={fadeInLogo}
              onExiting={fadeOutLogo}
              mountOnEnter
              unmountOnExit
            >
              <div className="img-container span-4 span-12-tablet">
                  <div className="img-wrapper">
                      <img srcSet={node.featuredImage.node.srcSet} />
                  </div>
              </div>    
            </Transition> 
            <Transition
				      in={activeSlide === i ? true :	false}
              timeout={baseDuration}
              appear={true}
              onEntering={fadeInLogo}
              onExiting={fadeOutLogo}
              mountOnEnter
              unmountOnExit
			      >
              <div className="content-container span-8 span-12-tablet grid-12">
                <div className="content-holder">
                  <h2>{node.title}</h2>
                  <div className="wysiwyg-inject" dangerouslySetInnerHTML={{__html: node.content}}/>
                </div>
                {/* <div className="fake-margin c4"/> */}
              </div>     
            </Transition> 
          </div>
      )
  })
   

	useEffect(() => {
		function updateSize() {
			setWindowSize( window.innerWidth )
		}
		window.addEventListener('resize', updateSize)
		updateSize()

		return () => window.removeEventListener('resize', updateSize)
	},[])
 
    if (windowSize < '1024')  
        numberOfSlides = posts?.length - 2
    else 
        numberOfSlides = posts?.length - 5
      

	return (
        <div className="master-wrap grid-12">
            {apollo.isLoading ? 
                <div className="span-12 loading">
                    <Loading />
                </div> 
            : 
            <>  
            <div className="slide-box span-12 span-12-tablet grid-12">
              {activePost}
            </div>
              <div className="slider-navi c4 span-12 span-12-tablet">
                <span className={`left-arrow ${currentSlide === 0 ? 'disabled' : ''}`} onClick={() => currentSlide > 0 ? setCurrentSlide(currentSlide-1) : null}></span>
                  <div className='box'>
                      {posts}
                  </div>
                <span className={`right-arrow ${currentSlide >= numberOfSlides ? 'disabled' : ''}`} onClick={() => currentSlide < numberOfSlides ? setCurrentSlide(currentSlide+1) : null}></span>
              </div>
            </>
            }

        </div>
	)
}

export default CustomBlock