import React, {useContext} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Loading from '../../../../../apollo/Loading'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {
    const apollo = useContext(ApolloContext)

    const posts = apollo.data?.studentStories.nodes.map((node, i) => { 
 
        return (
            <article className="single-post span-12 grid-12" key={i}>
                 <div className="img-container span-5">
                    <div className="img-wrapper">
                        <Link to={node.uri}>
                            <img srcSet={node.featuredImage.node.srcSet} />
                        </Link>
                    </div>
                </div>
                <div className="box span-7">
                    <h3>
                        <Link to={node.uri}>
                            {node.title}
                        </Link>
                    </h3>
                    <div className="excerpt" dangerouslySetInnerHTML={{__html: node.excerpt}}/>
                </div>
            </article>
        )
    })
    

	return (
        <div className="grid-12 ">
            <>
                <div className="span-3 grid-12 span-12-tablet  order-2-tablet">
                    <div className="button-container animated from-left delay-1 span-12 ">
                        <a href="https://portal.mru.edu/Applicant/InfoRequest.aspx" target="_blank" rel="noreferrer" className="button blue">APPLY NOW</a>
                        <a href="#" className="button gray">PLAN A VISIT</a>
                        <a href="#home-request-information" className="button yellow">REQUEST INFO</a>
                    </div> 
                </div>
                <div className="span-5 span-12-tablet animated delay-2 grid-12">

                {apollo.isLoading ? 
                  <div className="span-12 loading">
                    <Loading />
                  </div> 
                : 
                  posts
                }

                </div>
                <div className=" side-img span-4 span-12-tablet">
                    <div className="desc" dangerouslySetInnerHTML={{__html: wysiwyg}}/>
                    <div className="img-wrap animated from-right delay-2">
                        <Img fluid={img}
                        imgStyle={{objectFit: 'cover'}}
                        objectPosition='50% 50%'
                        className="image"
                        />
                    </div>
                </div>
            </>
        </div>
	)
}

export default CustomBlock