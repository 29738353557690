import React, {useContext} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Link } from 'gatsby'
import Loading from '../../../../../apollo/Loading'

function CustomBlock({ fields: field, posts: post }) {
    const apollo = useContext(ApolloContext)

    const calendar = 
       (
          <article className="single-post grid-12">
              <div className="img-container span-5 span-12-tablet">
                  <div className="img-wrapper">
                      <Link to={"/academic-calendar"}>
                          <img src={'https://cms.mru.edu/wp-content/uploads/2021/04/2324.jpg'}/>
                      </Link>
                  </div>
              </div>
              <Link className="box span-7 span-12-tablet" to={"/academic-calendar"}>
                  <div>
                      <h3>
                          ACADEMIC CALENDAR
                      </h3>
                      <div className="excerpt" >Find all important dates on the MRU Academic Calendar.</div>
                  </div>
              </Link>
          </article>
      )


    const posts = apollo.data?.sonhs.nodes.map((node, i) => {
        if(i>0) 
        return (
            <article className="single-post grid-12" key={i}>
                <div className="img-container span-5 span-12-tablet">
                    <div className="img-wrapper">
                        <Link to={node.uri}>
                            <img srcSet={node.featuredImage.node.srcSet} alt={node.title}/>
                        </Link>
                    </div>
                </div>
                <Link className="box span-7 span-12-tablet " to={node.uri}>
                    <div>
                        <h3>
                            {node.title}
                        </h3>
                        <div className="excerpt" dangerouslySetInnerHTML={{__html: node.excerpt}}/>
                    </div>
                </Link>
            </article>
        )
    })

    const lastPost = apollo.data?.messages.nodes.map((node, i) => {
      if(post && post[0] === node.uri) 
      return (
        <article className="single-post last-single-post" key={i}>
        <Link className="grid-12" to={node.uri}>
            <div className="single img-container span-12 span-12-tablet">
                <div className="img-wrapper">
                    <img srcSet={node.featuredImage.node.srcSet} alt={node.title} /> 
                </div>
            </div>
            <div className="box last-box span-12 span-12-tablet order-2-tablet">
                <h3>
                    {node.title}
                </h3>
                <div className="excerpt" dangerouslySetInnerHTML={{__html: node.excerpt}}/>
            </div>
        </Link>
    </article>
      )
  })

	return (
        <div className="grid-12 ">
            {apollo.isLoading ? 
                <div className="span-12 loading">
                   <Loading />
               </div> 
            : 
            <>
                <div className="span-7 span-12-tablet ">
                    {lastPost}
                </div>
                <div className="span-5 span-12-tablet ">
                  {calendar}
                    {posts}
                </div>
               
            </>
            }
        </div>
	)
}

export default CustomBlock