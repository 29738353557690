import React, { useRef } from 'react'
import './style.scss'
import { useState, useEffect } from "react";


import Img from "gatsby-image"
// import { Link } from 'gatsby'
import Intro from '../../../../../animations/Intro'
import { useInView } from 'react-intersection-observer'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Slider settin


function CustomSlider({ images: img, fields: field, wysiwygs: wysiwyg }) {
  const [refInView, ioInView] = useInView({ triggerOnce: true })

  const [nav1, setNav1] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  let slider1 = useRef(null);

  useEffect(() => {
    setNav1(slider1);
  }, [slider1]);

  const settings = {
    beforeChange: (current, next) => setCurrentSlide(next),
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    // fade: true,
    swipeToSlide: false,
    draggable: false,
    asNavFor: ".slider-nav",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          adaptiveHeight: true

        }
      },
    ]
  };

  return (

    <div ref={refInView} className='grid-12 c5'>

      <Intro visible={ioInView} in={{ fade: 750 }} delayIn={200} mounted={true} stay={true}>
        <div className="content grid-12">
          <div className="container c5">
            <div className="thumb-wrapper x1 t">
              {img?.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className={`${currentSlide === idx ? "active" : null} thumb`}
                    onClick={() => {
                      return slider1?.slickGoTo(idx)
                    }}>
                    <Img fluid={item}
                      imgStyle={{ objectFit: 'cover' }}
                      objectPosition='50% 50%'
                      className='image-main'
                    />
                    {field?.[idx] ? 
                    <h3 className='thumb-title' dangerouslySetInnerHTML={{ __html: field[idx] }}></h3>
                    : null }
                    {/* {currentSlide} */}
                  </div>
                )
              })}
            </div>
            <Slider {...settings} asNavFor={nav1}
              ref={slider => {
                slider1 = slider;
              }
              }>
              {img?.map((item, i) => (
                wysiwyg?.[i] ? 
                <div key={i}>
                  <div className={`wysiwyg-inject animated padd-3 padd-top-2`} dangerouslySetInnerHTML={{ __html: wysiwyg?.[i] }} />
                </div>
                : null
              ))}
            </Slider>

          </div>
        </div>
      </Intro>

    </div>
  )
}

export default CustomSlider