import React from 'react'
import './style.scss'

import HeroDetail from './HeroDetail'
import FormDetail from './FormDetail'
import SideNavDetail from './SideNavDetail'
import StudentStories from './StudentStories'
import CampusLife from './CampusLife'
import CampusLifeNew from './CampusLifeNew'
import MRUNews from './MRUNews'
import MRUNewsNew from './MRUNewsNew'
import AllNews from './AllNews'
import Academics from './Academics'
import Events from './Events'
import Timeline from './Timeline'
import SONHSNews from './SONHSNews'
import NursingPosts from './NursingPosts'
import NursingButtons from './NursingButtons'
import Degrees from './Degrees'
import FormLandingDetail from './FormLandingDetail'
import GetSupport from './GetSupport'
import FormBeInvolved from './FormBeInvolved'
import LocationMap from './LocationMap'
import JobForm from './JobForm'
import CampusGallery from './CampusGallery'
import FormNursingSchool from './FormNursingSchool'
import HomeAbout from './HomeAbout'
import ExplorePrograms from './ExplorePrograms'
import HeroContent from './HeroContent'
import BarStamp from './BarStamp'
import NextLanding from './NextLanding'
import HeroNextContent from './HeroNextContent'
import CustomSlider from './CustomSlider'

function ACFCustom(props) {

  // Images	
  const images = props.asset?.filter(obj => obj.acf_fc_layout === 'image')
    .map((node) => {
      return node.asset_img?.localFile?.childImageSharp.fluid
    })

  // Images	Responsive
  const imagesMobile = props.asset?.filter(obj => obj.acf_fc_layout === 'image')
    .map((node) => {
      return node.asset_img_responsive?.localFile?.childImageSharp.fluid
    })
    .filter(node => node)

  // Files	
  const files = props.asset?.filter(obj => obj.acf_fc_layout === 'file')
    .map((node) => {
      return node.asset_file
    })

  // Fields	
  const fields = props.asset?.filter(obj => obj.acf_fc_layout === 'field' || obj.fieldGroupName === 'Academic_Pagebuilder_Sections_Content_Block_Custom_Asset_Field')
    .map((node) => {
      if (node.assetField) {
        return node.assetField
      }
      return node.asset_field
    })

  // Anchors	
  const anchors = props.asset?.filter(obj => obj.acf_fc_layout === 'field' || obj.fieldGroupName === 'Academic_Pagebuilder_Sections_Content_Block_Custom_Asset_Field')
    .map((node) => {
      if (node.assetField) {
        return node.assetId
      }
      return node.asset_id
    })

  // WYSIWYGS	
  const wysiwygs = props.asset?.filter(obj => obj.acf_fc_layout === 'wysiwyg' || obj.fieldGroupName === 'Academic_Pagebuilder_Sections_Content_Block_Custom_Asset_Wysiwyg')
    .map((node) => {
      if (node.assetWysiwyg) {
        return node.assetWysiwyg
      }
      return node.asset_wysiwyg
    })

  // Maps	
  const maps = props.asset?.filter(obj => obj.acf_fc_layout === 'map')
    .map((node) => {
      return node.asset_map
    })

  // Posts
  const posts = props.asset?.filter(obj => obj.acf_fc_layout === 'post')
    .map((node) => {
      return node.asset_post?.replace(process.env.GATSBY_WP_URL, '')
    })

  // Gallery
  const gallery = props.asset?.filter(obj => obj.acf_fc_layout === 'gallery')


  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-home-detail') {
    return (
      <HeroDetail images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
    )
  }

  if ((props.acf_fc_layout === 'custom' || props.fieldGroupName === 'Academic_Pagebuilder_Sections_Content_Block_Custom') && props.anchor === 'custom-request-information') {
    return (
      <FormDetail fields={fields} />
    )
  }

  if ((props.acf_fc_layout === 'custom' || props.fieldGroupName === 'Academic_Pagebuilder_Sections_Content_Block_Custom') && props.anchor === 'custom-nursing-school-landing-form') {
    return (
      <FormNursingSchool fields={fields} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-campus-life') {
    return (
      <CampusLife fields={fields} files={files}/>
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-campus-life-new') {
    return (
      <CampusLifeNew fields={fields} files={files}/>
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-student-stories') {
    return (
      <StudentStories fields={fields} wysiwygs={wysiwygs} images={images} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-mru-news') {
    return (
      <MRUNews fields={fields} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-mru-news-new') {
    return (
      <MRUNewsNew fields={fields} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-all-news') {
    return (
      <AllNews fields={fields} />
    )
  }

  if ((props.acf_fc_layout === 'custom' || props.fieldGroupName === 'Academic_Pagebuilder_Sections_Content_Block_Custom') && props.anchor === 'custom-side-nav') {
    return (
      <SideNavDetail images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} anchors={anchors} />
    )
  }

  if ((props.acf_fc_layout === 'custom' || props.fieldGroupName === 'Academic_Pagebuilder_Sections_Content_Block_Custom') && props.anchor === 'custom-degrees') {
    return (
      <Degrees images={images} fields={fields} wysiwygs={wysiwygs} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-academics') {
    return (
      <Academics fields={fields} images={images} files={files} wysiwygs={wysiwygs} maps={maps} anchors={anchors} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-events') {
    return (
      <Events fields={fields} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-timeline') {
    return (
      <Timeline images={images} fields={fields} wysiwygs={wysiwygs} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-SONHS-news') {
    return (
      <SONHSNews fields={fields} posts={posts} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-nursing-posts') {
    return (
      <NursingPosts images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} anchors={anchors} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-nursing-buttons') {
    return (
      <NursingButtons fields={fields} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-request-information-landing') {
    return (
      <FormLandingDetail fields={fields} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-get-support') {
    return (
      <GetSupport images={images} fields={fields} wysiwygs={wysiwygs} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-be-involved') {
    return (
      <FormBeInvolved images={images} fields={fields} wysiwygs={wysiwygs} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-location-map') {
    return (
      <LocationMap images={images} fields={fields} wysiwygs={wysiwygs} maps={maps} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-job-form') {
    return (
      <JobForm images={images} fields={fields} wysiwygs={wysiwygs} maps={maps} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'campus-gallery') {
    return (
      <CampusGallery gallery={gallery} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-home-about') {
    return (
      <HomeAbout wysiwygs={wysiwygs} files={files} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-explore-programs') {
    return (
      <ExplorePrograms images={images} wysiwygs={wysiwygs} fields={fields} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-hero-content') {
    return (
      <HeroContent images={images} fields={fields} imagesMobile={imagesMobile}/>
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-next-landing') {
    return (
      <NextLanding images={images} wysiwygs={wysiwygs} fields={fields} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-bar-stamp') {
    return (
      <BarStamp images={images}/>
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-hero-next-content') {
    return (
      <HeroNextContent images={images} fields={fields} />
    )
  }

  if (props.acf_fc_layout === 'custom' && props.anchor === 'custom-slider') {
    return (
      <CustomSlider images={images} fields={fields} wysiwygs={wysiwygs}/>
    )
  }

  return false
}

export default ACFCustom