import React, {useContext} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Link } from 'gatsby'
import Loading from '../../../../../apollo/Loading'

function CustomBlock({ fields: field }) {
    const apollo = useContext(ApolloContext)


    const studentInvolvement = 
            <article className={`single-post animated from-left delay-2`} >
              <div className="wrap grid-12">
                <div className="box span-6 span-12-tablet order-2-tablet">
                    <h6 className="categories">
                      STUDENT LIFE
                    </h6>
                    <h3>
                    <Link to={'/student-life'}>
                        STUDENT INVOLVEMENT
                    </Link>
                    </h3>
                    <div className="excerpt" >
                      <p>Our mission is developing future leaders and we offer unlimited opportunities for involvement, leadership, and personal exploration.</p>
                    </div>
                    <p className="link">
                      <Link to={'/student-life'}>
                        LEARN MORE
                      </Link>
                    </p>
                </div>
                <div className="img-container span-6 span-12-tablet">
                    <div className="img-wrapper">
                        <Link to={'/student-life'}>
                            <img src={'https://cms.mru.edu/wp-content/uploads/2021/03/MRU-CORPORATERUN-DSC07220@2x.jpg'} alt=''/>
                        </Link>
                    </div>
                </div>
              </div>
          </article>
    
    const spotify = 
            <article className={`single-post animated from-left delay-3`} >
              <div className="wrap grid-12">
                <div className="box span-6 span-12-tablet order-2-tablet">
                    <h6 className="categories">
                      STUDENT LIFE
                    </h6>
                    <h3>
                    <Link to={'/student-life/#section-student-mru-radio'}>
                      SUBSCRIBE TO OUR SPOTIFY CHANNEL
                    </Link>
                    </h3>
                    <div className="excerpt" >
                      <p>Interviews, Music and more..</p>
                    </div>
                    <p className="link">
                      <Link to={'/student-life/#section-student-mru-radio'}>
                        LEARN MORE
                      </Link>
                    </p>
                </div>
                <div className="img-container span-6 span-12-tablet">
                    <div className="img-wrapper">
                        <Link to={'/student-life/#section-student-mru-radio'}>
                            <img src={'https://cms.mru.edu/wp-content/uploads/2021/03/276f273d11f8b9dbc0a9c55bb38ea8c6@2x.jpg'} alt='spotify'/>
                        </Link>
                    </div>
                </div>
              </div>
          </article>

    const posts = apollo.data?.campusLife.nodes.map((node, i) => {
        if(i>1)
        return null
        if(i>0) 
        return (
            <article className={`single-post animated from-left delay-${i}`} key={i}>
              <div className="wrap grid-12">
                <div className="box span-6 span-12-tablet order-2-tablet">
                    <h6 className="categories">
                        {apollo.data?.campusLife?.nodes[i].categories.nodes.map((node,i) => {
                            if(i>0) {
                                return ' / ' + node.name
                            }
                            return node.name
                        })}
                    </h6>
                    <h3>
                        <Link to={node.postId===885 ? '/student-life/#section-student-mru-radio' : node.uri}>
                            {node.title}
                        </Link>
                    </h3>
                    <div className="excerpt" dangerouslySetInnerHTML={{__html: node.excerpt}}/>
                    <p className="link">
                        <Link to={node.postId===885 ? '/student-life/#section-student-mru-radio' : node.uri}>
                            LEARN MORE
                        </Link>
                    </p>
                </div>
                <div className="img-container span-6 span-12-tablet">
                    <div className="img-wrapper">
                        <Link to={node.postId===885 ? '/student-life/#section-student-mru-radio' : node.uri}>
                            <img srcSet={node.featuredImage.node.srcSet} alt=''/>
                        </Link>
                    </div>
                </div>
              </div>
            </article>
        )
        return null
     
    })

    const lastPost = 
    <article className="single-post animated from-right last-single-post grid-12">
      <div className="wrap grid-12">
        <div className="box last-box span-12 span-12-tablet order-2-tablet">
            <h6 className="categories">
                {apollo.data?.campusLife?.nodes[0].categories.nodes.map((node,i) => {
                    if(i>0) {
                        return ' / ' + node.name
                    }
                    return node.name
                })}
            </h6>
            <h3>
                <Link to={apollo.data?.campusLife?.nodes[0].uri}>
                    {apollo.data?.campusLife?.nodes[0].title}
                </Link>
            </h3>
            <div className="excerpt" dangerouslySetInnerHTML={{__html: apollo.data?.campusLife?.nodes[0].excerpt}}/>
            <p className="last-link">
                <Link to={apollo.data?.campusLife?.nodes[0].uri}>
                    LEARN MORE
                </Link>
            </p>
        </div>
        <div className="single img-container span-12 span-12-tablet">
            <div className="img-wrapper">
                <Link to={apollo.data?.campusLife?.nodes[0].uri}>
                    <img srcSet={apollo.data?.campusLife?.nodes[0].featuredImage.node.srcSet} alt='' />
                </Link>
            </div>
        </div>
      </div>
    </article>

	return (
        <div className="grid-12 gap-3 gap-0-tablet">
            {apollo.isLoading ? 
                <div className="span-12 loading">
                  <Loading />
                </div> 
            : 
            <>
                <div className="span-7 span-12-tablet ">
                    {posts}
                    {studentInvolvement}
                    {spotify}
                </div>
                <div className="span-4 span-12-tablet ">
                    {lastPost}
                </div>
                <span className="scroll-down">scroll down</span>
            </>
            }
        </div>
	)
}

export default CustomBlock